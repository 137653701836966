import {
  Component, ElementRef, EventEmitter, Input, Output, ViewChild,
} from '@angular/core';
import {
  UploadStatus, UploadPhoto, FileTypes, PhotoService, UserActivityService, LoaderService, LaunchDarklyService,
  UploadService, MarketingOrderService, PromptDialogService, AppService, FeatureFlags, MarketingOrder, ProductCode,
  PhotoOrder, PhotoOrderStatus, PhotoOrderService,
} from '@lc/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { forkJoin, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UploadResultsDialogService } from '../../dialogs';

@Component({
  selector: 'lc-property-photo-upload',
  templateUrl: './property-photo-upload.component.html',
  styleUrls: ['./property-photo-upload.component.scss'],
})
export class PropertyPhotoUploadComponent {
  @Input() readonly marketingOrderId;
  @Output() readonly uploadResult = new EventEmitter<UploadPhoto[]>();

  @ViewChild('content') content: any;
  @ViewChild('photoInput') input: ElementRef<HTMLInputElement>;

  photoColorValidation$: Observable<Boolean>;
  successfulUploads = [];
  failedUploads = [];
  isProcessing = false;
  statuses: UploadStatus[];
  readonly fileTypes = FileTypes; // File validation constraints
  columns: string[] = ['uploads'];
  MAX_NON_PHOTOGRAPHER_PHOTOS_COUNT = 50;
  MAX_PHOTOGRAPHER_PHOTOS_COUNT = 100;
  MAX_ORDER_PHOTOS_COUNT = 150;
  appService = AppService;
  constructor(
    private photoService: PhotoService,
    private activityService: UserActivityService,
    private uploadResults: UploadResultsDialogService,
    private readonly uploadService: UploadService,
    private loaderService: LoaderService,
    private launchDarklyService: LaunchDarklyService,
    private marketingOrderService: MarketingOrderService,
    private readonly promptDialog: PromptDialogService,
    private photoOrderService: PhotoOrderService,
  ) {
    this.photoColorValidation$ = this.launchDarklyService.getFeature$(FeatureFlags.PHOTO_COLOR_VALIDATION, false);
  }

  async getFilesFromUploadEvent(event: NgxFileDropEntry[]) {
    const filePromises = [];
    event.forEach((droppedFile) => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        const filePromise = new Promise((resolve) => {
          fileEntry.file(resolve);
        });
        filePromises.push(filePromise);
      }
    });
    return Promise.all(filePromises);
  }

  handleDroppedFiles = async (event: NgxFileDropEntry[]) => {
    this.successfulUploads = [];
    this.failedUploads = [];
    const files = await this.getFilesFromUploadEvent(event);
    if (!this.isProcessing && !(await this.isMaxPhotosExceeded(files, this.marketingOrderId))) {
      this.uploadFiles(files);
    }
  };

  handleSelectedFiles = async (event: any) => {
    this.successfulUploads = [];
    this.failedUploads = [];
    if (event.target?.files?.length > 0
      && !(await this.isMaxPhotosExceeded(event.target?.files, this.marketingOrderId))) {
      this.uploadFiles(event.target.files);
    }
  };

  async isMaxPhotosExceeded(files: File[], _marketingOrderId?: string) {
    if (!_marketingOrderId) {
      return false;
    }

    const marketingOrder = await this.marketingOrderService.loadOrder(_marketingOrderId);

    const photographerPhotosCount = marketingOrder?.photos?.filter((photo) => photo.photographerId)?.length ?? 0;
    const totalOrderPhotos = (marketingOrder?.photos?.length ?? 0);

    if (AppService.isPhotoApp) {
      if (this.isPhotoOrderExist(marketingOrder)) {
        const photoOrder: PhotoOrder = await this.photoOrderService.getPhotoOrders$(_marketingOrderId).pipe(
          map((photoOrders) => photoOrders.find((po) => po.status !== PhotoOrderStatus.CANCELLED)),
          first(),
        ).toPromise();

        const totalPhotos = (photoOrder?.photos?.length ?? 0) + photographerPhotosCount + (files?.length ?? 0);

        if (totalPhotos > this.MAX_PHOTOGRAPHER_PHOTOS_COUNT) {
          this.promptDialog.openPrompt('Error', `You have exceeded the maximum limit of ${this.MAX_PHOTOGRAPHER_PHOTOS_COUNT} photos per listing.`, 'Ok');
          return true;
        }
      }
    } else if (totalOrderPhotos + files.length > this.MAX_ORDER_PHOTOS_COUNT) {
      this.promptDialog.openPrompt('Error', `You have exceeded the maximum limit of ${this.MAX_ORDER_PHOTOS_COUNT} photos per listing`, 'Ok');
      return true;
    }
    return false;
  }

  isPhotoOrderExist(marketingOrder: MarketingOrder): boolean {
    const photographyProduct = marketingOrder.getProduct(ProductCode.PROFESSIONAL_PHOTOGRAPHY);
    return photographyProduct != null && !photographyProduct.optedOut;
  }

  uploadAllWithStatuses(loadArray :any[]) {
    this.statuses = (loadArray || []).map((la) => {
      const uploadStatusObj = new UploadStatus(la.file);
      uploadStatusObj.presignURL = la.url;
      uploadStatusObj.publicURL = la.publicUrl;
      return uploadStatusObj;
    });

    const uploads$ = this.statuses.map((status) => this.photoService.preSignUploadWithStatus(status, 0.3));

    // On the UploadProgress event, update the progress status
    this.loaderService.show();
    forkJoin(uploads$)
      .subscribe(async (statuses) => {
        // Resume Timer
        this.activityService.startWatching();

        this.failedUploads = statuses.filter((status) => status.error).map((status) => `${status.file.name} : ${status.error}`);
        this.successfulUploads = statuses.filter((status) => !status.error).map((status) => status.file.name);

        if (this.failedUploads.length > 0) {
          this.uploadResults.openResults(this.failedUploads, this.successfulUploads, true).then(() => this.clearUploadStatuses());
        } else {
          this.clearUploadStatuses();
        }
        const thumbnailsRequest = {};

        for (const eachLoadArr of loadArray) {
          const failedUploadImageNames = statuses.filter((status) => status.error).map((status) => status.file.name);
          if (!failedUploadImageNames.find((fileName) => fileName === eachLoadArr.name)) {
            thumbnailsRequest[eachLoadArr.name] = eachLoadArr.publicUrl;
          }
        }

        const results = await this.uploadService.postImageUploadAndGetThumbnails(thumbnailsRequest).toPromise()
          .catch((error) => {
            // Resume Timer
            this.activityService.startWatching();
            this.loaderService.hide();
            throw new Error(error);
          });

        // We get 404 errors when fetching thumbnails/images from S3 if we don't pause for a bit.
        // Currently, a 2 second delay seems adequate to prevent these errors. However, a cold-start
        // lambda may exceed the delay time established here.
        await new Promise((r) => { setTimeout(r, 2000); });
        this.loaderService.hide();
        results.forEach((result) => {
          const status = statuses.find((status) => status.file.name === result.file);
          result.imageInfo = status?.imageInfo;
        });
        this.uploadResult.emit(results);
      }, (error) => {
        // Resume Timer
        this.activityService.startWatching();
        this.loaderService.hide();
        throw new Error(error);
      });
  }

  uploadFiles(files: File[]) {
    // Files is of type FileList, that does not support the simple files.map. Need to use spread operator
    const actualFiles = Array.from(files);
    const filesToPresign = {
      files: actualFiles.map((s) => {
        return {
          name: s.name,
          mimetype: s.type,
          marketingOrderId: this.marketingOrderId,
        };
      }),
    };

    this.activityService.stopWatching();

    this.uploadService.getPresignedUrlForAll(filesToPresign)
      .subscribe(
        (next) => {
          const loadArray = [];
          for (const fileIter in next) {
            if (fileIter) {
              const presignedData = {
                file: Array.from(files).find((f) => f.name === fileIter),
                url: next[fileIter],
                publicUrl: next[`${fileIter}_cloudfront-url`],
                name: fileIter,
              };
              if (presignedData.file) loadArray.push(presignedData);
            }
          }
          this.uploadAllWithStatuses(loadArray);
        },
        (error) => {
          this.activityService.startWatching();
          throw new Error(error);
        },
        () => { this.input.nativeElement.value = ''; },
      );
  }

  private clearUploadStatuses() {
    this.statuses = [];
    this.isProcessing = false;
  }

  public chooseFiles() {
    const element: HTMLElement = document.getElementById('uploadPhotos') as HTMLElement;
    element.click();
  }
}
