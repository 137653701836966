<div *ngIf="(viewModel$ | async) as viewModel" class="py-3 fx-column fx-gap-sm">

    <p class="mt-3">Add Multimedia Link</p>
    <lc-multi-media-item-form [formGroup]="addFormGroup" idPrefix="add"></lc-multi-media-item-form>

    <button class="fx-row x-end" [style.width]="'160px'" id="multimediaitem-add-link" type="button" mat-raised-button color="primary" [disabled]="!addFormGroup.valid"
            (click)="addLink(viewModel.photoOrder)">Submit Multimedia
    </button>

    <lc-multimedia-table *ngIf="viewModel?.multimediaLinks?.length" [showHeaders]="true" deleteEnabled="false" selectEnabled="false" editEnabled="false" [(items)]="viewModel.multimediaLinks"></lc-multimedia-table>

</div>
