import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PhotoOrder, PhotoOrderService } from '@lc/core';
import { Tab } from '@lc/ui';
import { Observable } from 'rxjs';
import {
  filter, map, startWith, switchMap, tap,
} from 'rxjs/operators';

@Component({
  selector: 'lc-manage-media',
  templateUrl: './manage-media.component.html',
  styleUrls: ['./manage-media.component.scss'],
})
export class ManageMediaComponent {
  readonly activeId$: Observable<string>;
  readonly tabs: Tab[] = [
    new Tab('Photos', 'photos', 'photos'),
    new Tab('Multimedia', 'multimedia', 'multimedia'),
  ];

  readonly photoOrder$: Observable<PhotoOrder>;
  photoOrderSelectedOptions:string;

  constructor(
    router: Router,
    private photoOrderService: PhotoOrderService,
    private activatedRoute: ActivatedRoute,
  ) {
    // Set the id of the active tab
    this.activeId$ = router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      startWith({}),
      map(() => activatedRoute.snapshot.children[0].data.activeId),
    );

    // Query the photo order
    this.photoOrder$ = this.activatedRoute.params.pipe(
      map((params) => params.photoOrderId),
      switchMap((orderId) => this.photoOrderService.getOrder$(orderId)),
      tap((order) => this.photoOrderSelectedOptions = (order.options || [])
        .filter((opt) => opt.selected).map((opt) => (opt.quantity ? `${opt.title} (${opt.quantity})` : opt.title))
        .join(', ')),
    );
  }
}
