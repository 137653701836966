import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  PhotoAgency, GetAllPhotographyAgencies, PHOTOGRAPHY_AGENCIES, GetAllPhotographyAgenciesComplete,
} from '@lc/core';
import { Observable } from 'rxjs';
import { ActionsSubject, Store } from '@ngrx/store';
import { CardOption } from '@lc/ui';
import { map, tap, switchMap } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

/**
 * the ImpersonatePhotographerComponent is used as a temporary component for impersonating
 * a professional photographer for the sole purpose of retrieving a set of
 * photographer appointment requests.
 */
@Component({
  selector: 'lc-app-impersonate-photographer',
  templateUrl: './impersonate-photographer.component.html',
  styleUrls: ['./impersonate-photographer.component.scss'],
})
export class ImpersonatePhotographerComponent {
  selectedPhotographer: PhotoAgency;
  readonly cardOptions$: Observable<CardOption<PhotoAgency>[]>;

  constructor(
    private router: Router,
    private store: Store<any>,
    private actionSubject: ActionsSubject,
  ) {
    this.store.dispatch(GetAllPhotographyAgencies({ payload: null }));

    this.cardOptions$ = this.actionSubject.pipe(
      ofType(GetAllPhotographyAgenciesComplete),
      switchMap(() => {
        return this.store.select(PHOTOGRAPHY_AGENCIES).pipe(
          map((photographers: PhotoAgency[]) => (photographers || []).map((photographer) => this.toDisplayOption(photographer))),
          tap((cardOptions) => {
            if (cardOptions.length === 1) {
              this.selectedPhotographer = cardOptions[0].model;
              this.onSubmit();
            }
          }),
        );
      }),
    );
  }

  onSelected(options: CardOption<PhotoAgency>[]) {
    if (options.length > 0) {
      this.selectedPhotographer = options[0].model;
    } else {
      this.selectedPhotographer = null;
    }
  }

  onSubmit() {
    // don't allow if user hasn't chosen a photographer
    if (!this.selectedPhotographer) { return; }

    // navigate to photography appointment requests
    this.router.navigate(['appointment-list', this.selectedPhotographer._id]);
  }

  /**
   * Converts the Options to a CardOption to be used in the display
   * @param option The photograhper to convert
   */
  private toDisplayOption(photographer: PhotoAgency): CardOption<PhotoAgency> {
    const cardOption = new CardOption(photographer, photographer.title, false, photographer.thumbnailUri, photographer.thumbnailUri);
    return cardOption;
  }
}
