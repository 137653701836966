<div class="p-4 fx-row x-center">
  <!-- 810 pixels supports 6 cards with their margins (135px each)-->
  <div class="fx-column fx-0-1" [style.width]="'810px'" [style.maxWidth]="'810px'">
      <h1 id="select-photographer-header">Please select your photographer</h1>

      <lc-card-options [options]="cardOptions$" valuePath="name" (selected)="onSelected($event)"></lc-card-options>

      <div class="fx-row">
        <button id="next-button" type="submit" mat-raised-button color="primary" [disabled]="!selectedPhotographer" (click)="onSubmit()">Next</button>
      </div>
  </div>
</div>
