// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

// Third Party Modules
import { NgxFileDropModule } from 'ngx-file-drop';

// LC Modules
import { CoreModule } from '@lc/core';
import {
  InputsModule, DialogsModule, ImagesModule, UICoreModule, SpinnerModule,
} from '@lc/ui';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { SharedComponentsLayoutModule } from './shared-components-layout.module';
import { SharedComponentsUiModule } from './shared-components-ui.module';

import { MultimediaTableComponent } from './media/multimedia-table/multimedia-table.component';
import { MultiMediaItemFormComponent } from './media/multi-media-item-form/multi-media-item-form.component';
import { PropertyPhotoUploadComponent } from './photos/photo-upload/property-photo-upload.component';
import { PhotoComponent } from './photos/photo-list/photo/photo.component';
import { PhotoListComponent } from './photos/photo-list/photo-list.component';
import { ProductPhotosComponent } from './photos/product-photos/product-photos.component';

const angularModules = [
  CommonModule, FormsModule, ReactiveFormsModule, MatSliderModule, MatBadgeModule,
  MatIconModule, MatButtonModule, DragDropModule, RouterModule, MatTooltipModule, MatTableModule,
  MatProgressSpinnerModule,
];
const thirdPartyModules = [NgxFileDropModule];
const internalModules = [
  CoreModule, MatProgressBarModule, MatMenuModule, MatDividerModule,
  InputsModule, ImagesModule, UICoreModule, DialogsModule, SharedComponentsUiModule, SharedComponentsLayoutModule,
  SpinnerModule,
];

const mediaComponents = [MultimediaTableComponent, MultiMediaItemFormComponent, PropertyPhotoUploadComponent, PhotoListComponent, ProductPhotosComponent];
const externalComponents = [PhotoComponent];

@NgModule({
  imports: [
    ...angularModules,
    ...thirdPartyModules,
    ...internalModules,
    ...externalComponents,
  ],
  declarations: [
    ...mediaComponents,
  ],
  exports: [
    ...mediaComponents,
  ],
  providers: [],
})
export class SharedComponentsMediaModule {}
