import { Component, Input, OnInit } from '@angular/core';
import { MultiMediaItemForm } from './multi-media-item.form';

@Component({
  selector: 'lc-multi-media-item-form',
  templateUrl: './multi-media-item-form.component.html',
  styleUrls: ['./multi-media-item-form.component.scss'],
})
export class MultiMediaItemFormComponent implements OnInit {
  @Input()
    formGroup: MultiMediaItemForm;

  @Input()
    idPrefix: string = 'add';

  constructor() {
  }

  ngOnInit(): void {
  }
}
