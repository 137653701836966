<div>
  <div *ngIf="items?.length">
    <div class="fx-row y-center fx-gap-md">
      <div>
        <lc-checkbox *ngIf="selectEnabled && !isProduct" label="SELECT ALL" [(ngModel)]="allSelected" (toggled)="toggleAll()" class="m-0"></lc-checkbox>
      </div>

      <button mat-button *ngIf="deleteEnabled && !isProduct" id="delete-selected-multimedia-items" [disabled]="noneSelected" (click)="deleteSelected()" class="text-faded">
        <div class="fx-row x-center y-center fx-gap-xs">
          <mat-icon>delete</mat-icon>
          <span>Delete Selected</span>
        </div>
      </button>
    </div>

    <!-- Table -->
    <div class="table-container mat-elevation-z1">
      <div class="table-wrapper">
        <table mat-table [dataSource]="viewModels" [class.no-header]="!showHeaders">
          <!-- Code column -->
          <ng-container matColumnDef="select">
            <th id="selection-header" mat-header-cell *matHeaderCellDef></th>
            <td class="selection-cell" mat-cell *matCellDef="let vm; let i = index">
              <lc-checkbox *ngIf="!isProduct" [idPrefix]="'multimedia-title-' + i" [(ngModel)]="vm.isSelected" (toggled)="onSelectionChanged(i)" class="mr-2"></lc-checkbox>
              <label *ngIf="isProduct" [id]="i + '-mm-label'" class="form-check-label" [class.no-label]="vm.item.title == null" class="mr-2">
                <input type="radio" class="mm-radio-button" [id]="i + '-mm-input'" [name]="name" [checked]="vm.isSelected" (click)="onMouseClick(i)"/>
              </label>
            </td>
          </ng-container>

          <!-- Name column -->
          <ng-container matColumnDef="name">
            <th id="name-header" mat-header-cell *matHeaderCellDef>Name</th>
            <td class="name-cell" mat-cell *matCellDef="let vm; let i = index">
              {{vm.item.title}}
            </td>
          </ng-container>

          <!-- Link column -->
          <ng-container matColumnDef="link">
            <th id="link-header" mat-header-cell *matHeaderCellDef>Link</th>
            <td class="link-cell" mat-cell *matCellDef="let vm; let i = index">
              <a [id]="'uri-multimedia-item-list-' + i" [href]="vm.item.uri" target="new">{{vm.item.uri}}</a>
            </td>
          </ng-container>

          <!-- Date column -->
          <ng-container matColumnDef="date">
            <th id="date-header" mat-header-cell *matHeaderCellDef>Date</th>
            <td class="date-cell" mat-cell *matCellDef="let vm; let i = index">
              {{vm.item.audit.updatedAt | date: 'MM/dd/yyyy HH:mm'}}
            </td>
          </ng-container>

          <!-- Agent column -->
          <ng-container matColumnDef="agent">
            <th id="agent-header" mat-header-cell *matHeaderCellDef>Agent</th>
            <td class="agent-cell" mat-cell *matCellDef="let vm; let i = index">
              {{vm.item.audit.updatedByFullName}}
            </td>
          </ng-container>

          <!-- Actions column -->
          <ng-container matColumnDef="actions">
            <th id="actions-header" mat-header-cell *matHeaderCellDef>Actions</th>
            <td class="actions-cell" mat-cell *matCellDef="let vm; let i = index">
              <button *ngIf="editEnabled && !isProduct" mat-icon-button [id]="'edit-multimedia-item-list-' + i" (click)="open(edit, vm.item)"  class="xs edit-multimedia-item" color="default">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="rows"></tr>
          <tr mat-row *matRowDef="let vm; columns: rows;"></tr>
        </table>
      </div>
    </div>
  </div>


  <ng-template #edit>
    <lc-base-dialog id="edit-dialog" title="Edit" (actionClicked)="onCancel()">
      <ng-template #content>
        <lc-multi-media-item-form [formGroup]="formGroup" idPrefix="update"></lc-multi-media-item-form>
      </ng-template>

      <ng-template #actions>
        <div class="fx-row x-end fx-gap-xs">
          <button id="multimedia-cancel-button" type="button" mat-raised-button (click)="onCancel()">Cancel</button>
          <button id="multimedia-update-button" type="button" mat-raised-button color="primary" [disabled]="!formGroup.valid" (click)="doUpdate()">Update</button>
        </div>
      </ng-template>
    </lc-base-dialog>
  </ng-template>
</div>
