<h5 *ngIf="photoType" class="mt-3 text-capitalize">{{photoType === 'DEFAULT' ? product.title : photoType.toLowerCase()}} photos</h5>

<div class="mb-3">
  <span *ngIf="!isWebsite">Drag and drop the images you'd like to use on the boxes</span>
  <span *ngIf="isWebsite">Drag and drop the images you'd like to use on the boxes and click on Publish to update the website</span>
</div>

<div class="mb-5 px-3" style="position: relative">
  <!-- Scroll Container-->
  <div #scrollable class="px-3 h-scroll-container">


    <!-- Drag and Drop Grid -->
    <!-- Note: Not using the lc-drag-n-drop due to complications -->
    <div style="position: relative; width: fit-content">

      <!-- Page Headers-->
      <div class="fx-row">
        <h5 *ngFor="let viewModel of productPhotos" class="w-160 fx-0-0" style="border-bottom: 1px solid;">{{viewModel.pageDisplay}}&nbsp;</h5>
      </div>

      <div cdkDropListGroup class="row cdk-drop-list-receiving">
        <div class="row">
          <div id="product-photos-drag" class="fx-row fx-gap-sm" style="height: 120px;" cdkDropList [cdkDropListData]="productPhotos" cdkDropListOrientation="horizontal" (cdkDropListDropped)="onDragAndDrop($event)">
            <lc-photo class="w-160 fx-0-0" cdkDrag cdkDragLockAxis="x" *ngFor="let viewModel of productPhotos; let i = index" [viewModel]="viewModel" (delete)="onDeletePhoto($event)" (edit)="onCropPhoto($event, i)" (click)="onOpenGallery(i)" (portraitDisabled)="onPortraitDisabled($event)"></lc-photo>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Left button-->
  <div class="scroll-left d-flex align-items-center justify-content-center">
    <button class="sm" mat-mini-fab (click)="onScroll('left')" color="default">
      <mat-icon>chevron_left</mat-icon>
    </button>
  </div>

  <!-- Right button-->
  <div class="scroll-right d-flex align-items-center justify-content-center">
    <button class="sm" mat-mini-fab (click)="onScroll('right')" color="default">
      <mat-icon>chevron_right</mat-icon>
    </button>

  </div>
</div>
