<div class="m-4 fx-row x-center">
  <div class="fx-column fx-0-1" [style.width]="'1400px'" [style.width]="'1400px'" *ngIf="viewModel$ | async as viewModel">

    <h1>Photography Appointment Requests for {{viewModel.agencyName}}</h1>

    <lc-order-filters *ngIf="areas$ | async as areas" [form]="filtersForm" [areas]="areas" (filter)="onFilter()">
    </lc-order-filters>

    <h5>Appointment Requests</h5>

    <div class="table-container mat-elevation-z1">
      <div class="loading-overlay" *ngIf="isLoading" >
        <mat-spinner></mat-spinner>
      </div>

      <div class="table-wrapper">
        <table id="appointments-table" mat-table [dataSource]="viewModel.photoOrderViewModels" class="w-100"
          matSortActive="date" matSortDisableClear matSortDirection="desc">

          <!-- Order Number Column-->
          <ng-container matColumnDef="order-number">
            <th mat-header-cell *matHeaderCellDef>Order Number</th>
            <td mat-cell *matCellDef="let vm">
              {{ vm.photoOrder.photoOrderId }}
            </td>
          </ng-container>

          <!-- Order Date Column-->
          <ng-container matColumnDef="order-date">
            <th mat-header-cell *matHeaderCellDef>Order Date</th>
            <td mat-cell *matCellDef="let vm">
              {{ vm.photoOrder.orderedAt | date:'shortDate' }}
            </td>
          </ng-container>

          <!-- Agent Column-->
          <ng-container matColumnDef="agent">
            <th mat-header-cell *matHeaderCellDef>Agent</th>
            <td mat-cell *matCellDef="let vm">
              {{ vm.photoOrder.agentName }}
            </td>
          </ng-container>

          <!-- Scheduled Date Column-->
          <ng-container matColumnDef="scheduled-date">
            <th mat-header-cell *matHeaderCellDef>Scheduled</th>
            <td mat-cell *matCellDef="let vm">
              <ng-container *ngIf="!vm.photoOrder?.appointment?.photoAgency?.useCustomScheduling; else customScheduling">
                {{ vm.photoOrder.appointment.scheduledAt | date:'short' : vm.photoOrder.appointment?.timezoneOffset}} {{vm.photoOrder.appointment?.timezone}}
              </ng-container>
              <ng-template #customScheduling>
                <span>Scheduled Externally</span>
              </ng-template>
            </td>
          </ng-container>

          <!-- Address Column-->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Property Address</th>
            <td mat-cell *matCellDef="let vm">
              {{ vm.photoOrder.propertyAddress | address }}
            </td>
          </ng-container>
          <!-- Status Column-->
          <ng-container matColumnDef="status">
            <th mat-header-cell  *matHeaderCellDef>Status</th>
            <td mat-cell  [matTooltip]= "vm.photoOrder.audit.updatedAt | date:'medium'" *matCellDef="let vm">
              {{ vm.photoOrder.status | photoOrderStatus }}
            </td>
          </ng-container>

          <!-- Actions Column-->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let vm">
               <!-- Menu Button -->
              <button *ngIf="vm.hasActions" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Options" class="photo-order-menu" color="default" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>

              <!-- Dropdown Menu -->
              <mat-menu #menu="matMenu" >
                <button id="start-order" mat-menu-item *ngIf="vm.canStart" (click)="start(vm.photoOrder)">
                  <mat-icon class="start">forward</mat-icon>
                  <span>Start Order</span>
                </button>
                <button id="complete-order" mat-menu-item *ngIf="vm.canComplete" (click)="complete(vm.photoOrder)">
                  <mat-icon class="cancel">done_all</mat-icon>
                  <span>Complete Order</span>
                </button>
                <button id="cancel-order"  mat-menu-item *ngIf="vm.canCancel" (click)="cancel(vm.photoOrder)">
                  <mat-icon class="cancel">block</mat-icon>
                  <span>Cancel Order</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns;" (click)="onClick(row)" [ngClass]="row.canClick ? 'clickable' : 'click-disabled'" ></tr>
        </table>
      </div>
      <mat-paginator [length]="pageRequestForm?.totalCount" [pageSize]="pageRequestForm?.pageSize?.value" [pageSizeOptions]="pageRequestForm?.pageSizeOptions" [pageIndex]="pageRequestForm?.pageNumber?.value - 1" (page)="onPageChanged($event)"></mat-paginator>
    </div>

    <div *ngIf="viewModel.hasMultipleAgencies" class="my-4">
      <button id="back-button" type="submit" mat-raised-button [routerLink]="['/']">Back to Photographers</button>
    </div>
  </div>
</div>
