import { BaseForm, CustomValidator } from '@lc/core';
import { FormControl, Validators } from '@angular/forms';

export class MultiMediaItemForm extends BaseForm {
  constructor(item?: any) {
    super({
      title: new FormControl(null, [Validators.required, Validators.maxLength(30)]),
      uri: new FormControl(null, [Validators.required, CustomValidator.validateSecureUri]),
    });

    if (item) {
      this.patchValue(item);
    }
  }
}
