<ng-container *ngIf="viewModel$ | async as viewModel">
  <div class="my-3 fx-column">

    <lc-property-photo-upload (uploadResult)="uploadResult(viewModel.photoOrder, $event)" [marketingOrderId]="viewModel.photoOrder.marketingOrderId"></lc-property-photo-upload>

    <!-- Working Photos Section -->
    <div *ngIf="viewModel.workingPhotos as workingPhotos" id="unsubmitted-photos-section">
      <div class="mt-5">
        <h4>{{workingPhotos.length}} Unsubmitted</h4>
      </div>
      <button id="submit-photos" mat-raised-button type="submit" [disabled]="!isSubmitEnabled" (lcDebounceClick)="submitPhotos(viewModel.photoOrder, workingPhotos)" >Submit Selected</button>

      <div *ngIf="!workingPhotos.length" class="text-center"><h5 class="text-faded font-weight-bold py-3 my-4">No photos uploaded yet</h5></div>
      <lc-photo-list
        *ngIf="workingPhotos.length"
        [viewModels]="workingPhotos"
        [marketingOrder]="null"
        (updatePhotos)="updateWorkingPhotos(viewModel.photoOrder, $event)"
        [favoritesEnabled]="false"
        (selected)="onUpdateSelectedPhotos($event)"
      ></lc-photo-list>

    </div>

    <!-- Submitted Photos Section -->
    <div *ngIf="viewModel.submittedPhotos" id="submitted-photos-section">

      <div class="mt-5">
        <h4>{{viewModel.submittedPhotos?.length}} Submitted</h4>
      </div>

      <div *ngIf="!viewModel.submittedPhotos.length" class="text-center"><h5 class="text-faded font-weight-bold py-3 my-4">No photos submitted yet</h5></div>
      <lc-photo-list *ngIf="viewModel.submittedPhotos?.length" [viewModels]="viewModel.submittedPhotos" [marketingOrder]="null"
        [favoritesEnabled]="false"
        [numberOrderingEnabled]="false"
        [dragDropEnabled]="false"
        [deleteEnabled]="false"
        [downloadEnabled]="false"
        [showMenu]="false"
        [selectEnabled]="false">
      </lc-photo-list>
    </div>

    <div *ngIf="viewModel.canComplete" class="d-flex justify-content-end">
      <button id="complete-order" type="submit" mat-raised-button (click)="onComplete(viewModel.photoOrder)">Complete Order</button>
    </div>

  </div>
</ng-container>
