import { FormControl } from '@angular/forms';
import { BaseForm } from '@lc/core';

/**
 * Model used to query the API with pagination
 */
export class PageRequest {
  constructor(public pageNumber: number, public pageSize: number) {
  }
}

/**
 * Form used to query the API with pagination
 */
export class PageRequestForm extends BaseForm<PageRequest> {
  /** The Original Model */
  originalModel: Partial<PageRequest>;

  /** The total count of the records, used to display in the paginator */
  totalCount: number;

  /** The page size options to display in the paginator */
  readonly pageSizeOptions: number[];

  /**
   * The pageNumber property that will get submitted to the API when 'form.value' is used
   */
  get pageNumber() { return this.getControl('pageNumber'); }

  /**
   * The pageSize property that will get submitted to the API when 'form.value' is used
   */
  get pageSize() { return this.getControl('pageSize'); }

  /**
   * Constructs the form that will be used during pagination of API queries.
   * Retrieve the pageRequest parameters by using form.value;
   * @param model The model properties to initialize the form value to
   * @param options Additional options used to display the paginator
   */
  constructor(model?: Partial<PageRequest>, options?: { pageSizes?: number[] }) {
    super({
      pageNumber: new FormControl(model?.pageNumber ?? 1),
      pageSize: new FormControl(model?.pageSize ?? 10),
    });
    this.originalModel = model || { pageNumber: 1, pageSize: 10 };
    this.pageSizeOptions = options?.pageSizes || [10, 25, 50, 100];
  }

  reset(options?: { emitEvent: boolean }) {
    super.reset(this.originalModel, options);
  }
}
