import { Component } from '@angular/core';
import {
  PhotoOrder, PhotoOrderService, UploadPhoto, ListingPhoto,
  MarketingOrderService, PhotoOrderStatus, ToasterService, PromptDialogService, UIConstants,
} from '@lc/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PhotoViewModel } from '@lc/shared-components-media';
import { switchMap, map, tap } from 'rxjs/operators';

class ViewModel {
  readonly canComplete: boolean;
  readonly workingPhotos: PhotoViewModel[];
  readonly submittedPhotos: PhotoViewModel[];
  readonly agencyName: string;

  constructor(readonly photoOrder: PhotoOrder, readonly orderPhotos: ListingPhoto[]) {
    this.agencyName = photoOrder?.appointment?.photoAgency?.title;
    this.canComplete = photoOrder.status === PhotoOrderStatus.SUBMITTING;
    this.submittedPhotos = (orderPhotos || []).map((photo) => new PhotoViewModel(photo));
    this.workingPhotos = photoOrder.photos.map((photo, index) => {
      photo.order = index + 1;
      return new PhotoViewModel(photo);
    });
  }
}
@Component({
  selector: 'lc-app-manage-photos',
  templateUrl: './manage-photos.component.html',
  styleUrls: ['./manage-photos.component.scss'],
})
export class ManagePhotosComponent {
  readonly viewModel$: Observable<ViewModel>;
  isSubmitEnabled: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private marketingOrderService: MarketingOrderService,
    private photoOrderService: PhotoOrderService,
    private toasterService: ToasterService,
    private dialogService: PromptDialogService,
  ) {
    this.viewModel$ = this.route.params.pipe(
      map((params) => params.photoOrderId),
      switchMap((orderId) => this.photoOrderService.getOrder$(orderId)),
      switchMap((photoOrder) => this.marketingOrderService.getOrderPhotos(photoOrder.marketingOrderId, photoOrder.photoAgencyId).pipe(
        map((orderPhotos) => new ViewModel(photoOrder, orderPhotos)),
        tap((vm) => this.onUpdateSelectedPhotos(vm.workingPhotos?.filter((photo) => photo.isSelected))),
      )),
    );
  }

  async uploadResult(photoOrder: PhotoOrder, newPhotos: UploadPhoto[]) {
    await this.photoOrderService.addPhotoFromUploadAndUpdatePhotos(photoOrder, newPhotos);
  }

  async updateWorkingPhotos(photoOrder: PhotoOrder, photos: ListingPhoto[]) {
    photos.forEach((photo, index) => photo.order = index + 1);
    await this.photoOrderService.setPhotos(photoOrder, photos);
  }

  onUpdateSelectedPhotos(selectedViewModels: PhotoViewModel[]) {
    this.isSubmitEnabled = (selectedViewModels || []).length > 0;
  }

  async submitPhotos(photoOrder: PhotoOrder, viewModels: PhotoViewModel[]) {
    const photoUrls = viewModels.filter((vm) => vm.isSelected).map((vm) => vm.photo.uri);
    await this.photoOrderService.submitPhotos(photoOrder, photoUrls);
  }

  /** Prompt the user and then proceeds to complete the order */
  async onComplete(photoOrder: PhotoOrder) {
    // Prompt user to confirm
    const message = 'This confirms you are completing this order. Do you want to continue?';
    const response = await this.dialogService.openPrompt(UIConstants.CONFIRM, message, UIConstants.YES, [UIConstants.NO]);
    if (response?.text !== UIConstants.YES) { return; } // Don't do anything if they did not click ok

    // Make a call to complete order
    await this.photoOrderService.completeOrder(photoOrder)
      .then(() => this.router.navigate([`/appointment-list/${photoOrder.photoAgencyId}`]))
      .catch(() => this.toasterService.showError('Failed to complete order'));
  }
}
